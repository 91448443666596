.cookie-consent-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cookie-consent-wrapper {
  max-width: 1200px;
  margin: 0 0 0 16px;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .cookie-consent-container {
    padding: 10px;
  }
  
  .cookie-consent-wrapper {
    width: calc(100% - 32px);
    max-width: 100%;
    margin: 0 auto 70px auto; /* Added bottom margin to avoid overlap with chat */
  }
  
  .cookie-consent-banner {
    padding: 15px;
  }
  
  .cookie-consent-content h2 {
    font-size: 18px;
  }
  
  .cookie-consent-content p {
    font-size: 13px;
  }
  
  .cookie-button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .cookie-consent-actions {
    flex-direction: column;
    width: 100%;
  }
  
  .cookie-button {
    width: 100%;
  }
}

.cookie-consent-banner {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

@media (min-width: 768px) {
  .cookie-consent-banner {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.cookie-consent-content {
  flex: 1;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .cookie-consent-content {
    margin-bottom: 0;
    margin-right: 20px;
  }
}

.cookie-consent-content h2 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
  color: #132441;
}

.cookie-consent-content p {
  margin: 0;
  color: #555;
  line-height: 1.5;
  font-size: 14px;
}

.cookie-consent-content a {
  color: #3451A3;
  text-decoration: none;
}

.cookie-consent-content a:hover {
  text-decoration: underline;
}

.cookie-consent-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.cookie-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  border: none;
}

.cookie-button.primary {
  background-color: #3451A3;
  color: white;
}

.cookie-button.primary:hover {
  background-color: #283d7d;
}

.cookie-button.secondary {
  background-color: #f1f1f1;
  color: #333;
}

.cookie-button.secondary:hover {
  background-color: #e5e5e5;
}

/* Settings panel styles */
.cookie-settings {
  padding: 20px;
}

.cookie-settings h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  color: #132441;
}

.settings-options {
  margin-bottom: 20px;
}

.setting-item {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.setting-item:last-child {
  border-bottom: none;
}

.setting-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.setting-header label {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #132441;
}

.setting-header input[type="checkbox"] {
  margin-right: 10px;
}

.setting-item p {
  margin: 0;
  font-size: 14px;
  color: #666;
  line-height: 1.4;
}

.settings-actions {
  display: flex;
  justify-content: flex-end;
}

/* Add a link at the bottom of the page to reopen cookie settings */
.cookie-settings-link {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f1f1f1;
  color: #333;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 9998;
}

.cookie-settings-link:hover {
  background-color: #e5e5e5;
} 